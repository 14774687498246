import React, { useState } from 'react';
import moment from 'moment';
import uuid from 'uuid/v4';
import { LineChart, XAxis, YAxis, Tooltip, Line, CartesianGrid } from 'recharts';
import Content from '@/components/ChartContent';

const tableColumns = [
  {
    title: 'Estação',
    dataIndex: 'station',
    key: 'station'
  },
  {
    title: 'Dia',
    dataIndex: 'week',
    key: 'week'
  },
  {
    title: 'Umidade relativa',
    dataIndex: 'value',
    key: 'value'
  }
];

export default function RelativeHumidity() {
  const [data, setData] = useState([]);

  const formatter = data => {
    const max = data.points.observed.max_rel_humidity;
    const min = data.points.observed.min_rel_humidity;

    const aggregate = max.map((humidity, iterate) => ({
      week: data.periods[iterate],
      value: ((humidity + min[iterate]) / 2).toFixed(0)
    }));

    setData(aggregate);
  }

  const tableSource = data.map(({ week, value }) => ({
    week: moment(week).format('DD MMM'),
    value: `${value}%`,
    key: uuid()
  }));

  const tickFormatter = tick => moment(tick).format('DD.MMM');

  return (
    <Content
      tableColumns={tableColumns}
      chartSource={data}
      tableSource={tableSource}
      getCallable={formatter}
    >
      <LineChart data={data} margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
        <XAxis dataKey="week" tickFormatter={tickFormatter} />
        <YAxis tickFormatter={tick => `${tick}%`} />

        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Line
          type="monotone"
          name="Umidade relativa"
          dataKey="value"
          fillOpacity={1}
          stroke="#1dbe72"
          fill="#1dbe72"
        />
      </LineChart>
    </Content>
  )
}
