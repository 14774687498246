import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { Content } from '@/containers';
import {
  Forecast,
  Mapeable,
  Chart,
  Data,
  Boletins,
  BoletinsSingle,
  Alerts,
  AlertsSingle,
  News,
  NewsSingle,
  Info,
} from '@/pages';

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Content>
          <Route exact={true} path="/" component={Forecast} />
          <Route path="/agro/mapa" component={Mapeable} />
          <Route path="/agro/grafico" component={Chart} />
          <Route path="/dados" component={Data} />
          <Route exact={true} path="/boletins" component={Boletins} />
          <Route path="/boletins/:id" component={BoletinsSingle} />
          <Route exact={true} path="/noticias" component={News} />
          <Route path="/noticias/:id" component={NewsSingle} />
          <Route exact={true} path="/alertas" component={Alerts} />
          <Route path="/alertas/:id" component={AlertsSingle} />
          <Route exact={true} path="/informacoes" component={Info} />
        </Content>
      </Switch>
    </BrowserRouter>
  )
}

export default Router
