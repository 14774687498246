import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import './index.styl';

const PauseButton = memo(({ pauseLayer }) => {
  const [buttonText, setButtonText] = useState({
    text: 'Pause',
    icon: 'pause'
  })

  const onClick = () => {
    buttonText.text === 'Pause'
      ? setButtonText({
          text: 'Play',
          icon: 'caret-right'
        })
      : setButtonText({
          text: 'Pause',
          icon: 'pause'
        })

    pauseLayer();
  }

  return (
    <div></div>
    // <div className="pause-button" onClick={onClick}>
    //   <Icon className="icon" type={buttonText.icon} />
    //   <p>{buttonText.text}</p>
    // </div>
  )
})

PauseButton.propTypes = {
  pauseLayer: PropTypes.func.isRequired
}

export default PauseButton
