import React from 'react';
import PropTypes from 'prop-types';
import './index.styl';

const Spotlight = ({ data }) => {
  return (
    <div className={`spotlight ${data.cor}`}>
      <h3>{data.titulo}</h3>
    </div>
  )
}

Spotlight.propTypes = {
  data: PropTypes.object,
}

export default Spotlight;