import React, { createContext, useState } from 'react';

export const AgroContext = createContext({
  station: {},
  period: {},
  data: {},
});

export const AgroProvider = ({ children }) => {
  const [data, setData] = useState({});

  const value = {
    data,
    setData
  };

  return <AgroContext.Provider value={value}>{children}</AgroContext.Provider>

}