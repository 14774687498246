import React from 'react';
import moment from 'moment';
import locale from "antd/lib/locale-provider/pt_PT";
import { Layout, Radio, Typography, DatePicker, LocaleProvider } from 'antd';
import './index.styl';

const { Sider } = Layout;
const { Title } = Typography;
const { MonthPicker } = DatePicker;
const RadioGroup =  Radio.Group;

const BoletinsSider = ({ type, setType, setMonth, setYear }) => {
  const disabledDate = date => date < moment('2016-01-01T00:00:00Z') || date > moment().subtract(1, 'days');

  const onChangeRadio = e => setType(e.target.value);
  const onChageDate = e => {
    setMonth(e.month() + 1);
    setYear(e.year());
  }

  return (
    <LocaleProvider locale={locale}>
      <Sider width={250} className="Boletins__sider">
        <div className="Boletins__sider--period">
          <Title level={2}>
            Periodo
          </Title>
            <MonthPicker
            placeholder={'Escolher mês'}
            format={'MM/YYYY'}
            disabledDate={disabledDate}
            onChange={onChageDate}
            />
        </div>
        <div className="Boletins__sider--boxes">
          <Title level={2}>
            Mostrar
          </Title>
          <RadioGroup onChange={onChangeRadio} value={type}>
            <Radio value={'Diario'}>Boletim Diário</Radio>
            <Radio value={'Semanal'}>Boletim Semanal</Radio>
            <Radio value={'Mensal'}>Boletim Mensal</Radio>
            <Radio value={'Especial'}>Boletim Especial</Radio>
          </RadioGroup>

        </div>
      </Sider>
    </LocaleProvider>
  )
}

export default BoletinsSider;
