import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './index.styl';

const TabHeader = memo(({ title, description, onClick }) => {
  return (
    <div onClick={onClick} className="tab-header">
      <p className="title">{title}</p>
      <p className="description">{description}</p>
    </div>
  )
})

TabHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default TabHeader
