import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const MapBoundsContext = createContext({});

export function MapBoundsProvider({ children }) {
  const [mapBounds, setMapBounds] = useState([
    [-22.55235365216548, -43.70288085937501],
    [-37.542762387234845, -63.13916015625001]
  ]);

  const value = {
    mapBounds,
    setMapBounds
  }

  return (
    <MapBoundsContext.Provider value={value}>
      {children}
    </MapBoundsContext.Provider>
  )
}

MapBoundsProvider.propTypes = {
  children: PropTypes.node.isRequired
}
