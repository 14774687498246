import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Layout, Icon } from 'antd';
import { getBulletins } from '@/services/salaDeSituacaoApi';
import { If } from '@/components';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { CollapsedContext } from '@/contexts';
import './index.styl';

const { Sider } = Layout;

export const BoletinsSidebar = () => {
  const [data, setData] = useState();
  const { collapsed, setCollapsed } = useContext(CollapsedContext);
  const [isLoading, setIsLoading] = useState(true);

  const toggleCollapsed = () => setCollapsed(!collapsed);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const response = await getBulletins({ type: 'Diario', perPage: 4 });
      setData(response);
      setIsLoading(false)
    }
    fetchData();
  }, []);

  const renderBoletins = () => {
    return _.map(data.data, boletim => {
      return (
        <div className="PdfPreview">
          <div className="PdfPreview__container">
            <div className="PdfPreview__container__document">
            <Link to={`/boletins/${boletim.id}`}>
              <Document
                file={`data:application/pdf;base64,${boletim.base64}`}
                loading="Carregando Documento!"
                renderMode="canvas"
              >
                <Page
                  pageNumber={1}
                  width={350}
                  scale={2}
                />
                <span>{boletim.titulo}</span>
              </Document>
            </Link>
          </div>
        </div>
      </div>
      )
    });
  }

  return (
    <Sider collapsed={collapsed} width={250} className="BoletinsSidebar__sider">
      <Icon
        className="toggleable"
        onClick={toggleCollapsed}
        type={collapsed ? 'menu-fold' : 'menu-unfold'}
      />
      <If test={!collapsed}>
      <div className="BoletinsSidebar">
        <h2>Boletins Recentes</h2>
        <If test={!isLoading}>
          {!isLoading && renderBoletins()}
        </If>
      </div>
      <div className="BoletinsSidebar__options--wrapper">
          <Link to={{ pathname: '/boletins', state: 'Diario'}}>
            <div className="BoletinsSidebar__option">Boletins Diários</div>
          </Link>
          <Link to={{ pathname: '/boletins', state: 'Semanal'}}>
            <div className="BoletinsSidebar__option">Boletins Semanais</div>
          </Link>
          <Link to={{ pathname: '/boletins', state: 'Mensal'}}>
            <div className="BoletinsSidebar__option">Boletins Mensais</div>
          </Link>
          <Link to={{ pathname: '/boletins', state: 'Especial'}}>
            <div className="BoletinsSidebar__option">Boletins Especiais</div>
          </Link>
      </div>
      </If>
    </Sider>  
  )
}
