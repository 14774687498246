import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const LoadingContext = createContext({});

export function LoadingProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  const value = {
    isLoading,
    setIsLoading
  };

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  )
}

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired
}
