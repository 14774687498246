import React, { useState } from 'react';
import moment from 'moment';
import uuid from 'uuid/v4';
import { AreaChart, XAxis, YAxis, Tooltip, Area, CartesianGrid } from 'recharts';
import Content from '@/components/ChartContent';

const tableColumns = [
  {
    title: 'Estação',
    dataIndex: 'station',
    key: 'station'
  },
  {
    title: 'Dia',
    dataIndex: 'week',
    key: 'week'
  },
  {
    title: 'Excedente',
    dataIndex: 'excedent',
    key: 'excedent'
  },
  {
    title: 'Déficit',
    dataIndex: 'deficit',
    key: 'deficit'
  }
];

export default function HybridBalance() {
  const [data, setData] = useState([]);
  const formatter = (data) => setData(data.waterBalance);

  const chartSource = data.map(({ data, exc, def }) => ({
    week: data,
    value: (exc - def).toFixed(1)
  }));

  const tableSource = data.map(({ data, exc, def }) => ({
    week: moment(data).format('DD MMM'),
    excedent: exc.toFixed(1),
    deficit: def.toFixed(1),
    key: uuid()
  }));

  const gradientOffset = () => {
    const dataMax = Math.max(...chartSource.map(i => i.value))
    const dataMin = Math.min(...chartSource.map(i => i.value))

    if (dataMax <= 0) {
      return 0
    }

    if (dataMin >= 0) {
      return 1
    }

    return dataMax / (dataMax - dataMin)
  }

  const tickFormatter = tick => moment(tick).format('DD.MMM');

  const offset = gradientOffset();

  return (
    <Content
      tableColumns={tableColumns}
      chartSource={chartSource}
      tableSource={tableSource}
      getCallable={formatter}
    >
      <AreaChart
        data={chartSource}
        margin={{ top: 5, right: 0, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
            <stop offset={offset} stopColor="#40a9ff" stopOpacity={1} />
            <stop offset={offset} stopColor="#ff4d4f" stopOpacity={1} />
          </linearGradient>
        </defs>

        <XAxis dataKey="week" tickFormatter={tickFormatter} />
        <YAxis tickFormatter={tick => `${tick} mm`} domain={[-5,35]} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Area
          type="monotone"
          name="Balanço Hídrico"
          dataKey="value"
          fillOpacity={1}
          stroke="url(#colorValue)"
          fill="url(#colorValue)"
        />
      </AreaChart>
    </Content>
  )
}
