import React, { useState, useEffect } from 'react';
import { getStations } from '@/services/salaDeSituacaoApi';
import { MarkerWithPopup } from '@/components';
import uuid from 'uuid/v4';

export const MapMarkers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [stations, setStations] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const res = await getStations();
      setStations(res);
      setIsLoading(false);
    }
    fetchData();
  }, []);
  
  if (!isLoading) {
    const items = stations.map(({...props}) => {
      return (
        <MarkerWithPopup key={uuid()} {...props} />
      )}
    )
  return <>{items}</>
  } else {
    return <></>
  }
}
