import React from 'react';
import { Row, Col, Typography } from 'antd';
import _ from 'lodash';
import catiaPhoto from '@/resources/catia.jpg';
import ingridPhoto from '@/resources/ingridpetry.jpg';
import lucasPhoto from '@/resources/lucasf.jpg';
import pedroPhoto from '@/resources/pedroC.jpg';
import vanessaPhoto from '@/resources/vanessagehm.jpg';

import './index.styl';

const { Title } = Typography;

const teamMap = [
  {
    photo: catiaPhoto,
    name: 'Cátia Valente',
    specialization: 'Meteorologista Mestre em Sensoreamento Remoto e Meteorologia',
    job: 'Coordernadora da equipe operacional, responsável pela área de Meteorologia',
  },
  {
    photo: ingridPhoto,
    name: 'Ingrid Petry',
    specialization: 'Engenheira Sanitarista e Ambiental Mestre em Recursos Hídricos e Saneamento Ambiental, Doutoranda em Recursos Hídricos e Saneamento Ambiental',
    job: 'Responsável pela áreae Hidrologia',
  },
  {
    photo: lucasPhoto,
    name: 'Lucas Fagundes',
    specialization: 'Meteorologista Mestre em Meteorologia',
    job: 'Responsável pela ár Meteorologia',
  },
  {
    photo: pedroPhoto,
    name: 'Pedro Camargo',
    specialization: 'Engenheiro Hídrico Mestrando em Recursos Hídricos e Saneamento Ambiental',
    job: 'Responsável pela área de Hidrologia'
  },
 
   {
    photo: vanessaPhoto,
    name: 'Vanessa Gehm',
    specialization: 'Meteorologista Mestre em Meteorologia',
    job: 'Responsável pela áreae Meteorologia',
  }

];

const Team = () => {
  const renderTeamCard = () => {
    return _.map(teamMap, person => {
      return (
        <Col className="Team__content" span={8} xs={24} sm={12} lg={6}>
          <img src={person.photo} />
          <div className="Team__name">
            {person.name}
          </div>
          <div className="Team__specialization">
            {person.specialization}
          </div>
          <div className="Team__job">
            {person.job}
          </div>
        </Col>
      )
    })
  }
  return (
    <Row  id="equipe" className="Row" type="flex" justify="center">
      <Col className="Team__wrapper" xs={24} lg={20}>
      <Col xs={24}>
        <Title className="Team__title" level={2}>
          Equipe de operação da Sala de Situação
        </Title>
        <Row className="Row" type="flex" justify="center">
          <Col className="Team__subtitle" xs={20} lg={16}>
            <p>
            A atividade de monitoramento e suporte técnico realizado pela Sala ocorre
            24 horas por dia, sete dias na semana. No espaço físico da Sala de Situação atuam
            quatro profissionais das áreas de meteorologia e hidrologia,
            em horário comercial e em regime de plantão em eventos adversos.
            Além da equipe de operação, as atividades da Sala são apoiadas por profissionais
            da área de desenvolvimento e tecnologia da informação. Os serviços especializados
            para a operação da Sala de Situação são supervisionados pela equipe de técnicos da SEMA.
            </p>
          </Col>
        </Row>
        </Col>
        {renderTeamCard()}
      </Col>
    </Row>
  )
}

export default Team;
