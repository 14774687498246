import React, { useState } from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash';
import moment from 'moment';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { If } from '@/components';
import { getStation, ANA_STATION, SEAPI_STATION } from '@/services/salaDeSituacaoApi';
import { Spin, Icon as Iconable } from 'antd';
import { capitalize } from '@/utils';
import './index.styl';

const groupingUnits = [[
  'minute',
  [15]
  ]];

const hcHelper = {
  rangeSelector : {
    buttons: [{
      type: 'day',
      count: 1,
      text: '1D'
    },{
      type: 'day',
      count: 7,
      text: '7D'
    },
    {
      type: 'day',
      count: 14,
      text: '14D'
    },
    {
      type: 'all',
      text: 'Tudo'
    }],
    selected : 1
  },
  title: {
    text: ''
  },
  subtitle: {
      text: ''
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    shared: true,
    split: false,
  },
  yAxis: [{
      title: {
          text: 'Precipitação (mm)',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      },
      tickPixelInterval: 35,
      labels: {
          format: '{value} mm',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      },
      lineWidth: 2,
      opposite: false,
    },
    {
      title: {
          text: 'Níveis (m)',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      },
      tickPixelInterval: 35,
      labels: {
          format: '{value} m',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      },
      lineWidth: 2,
  }],
  plotOptions: {
    spline: {
        marker: {
            enabled: false
        },
    }
  },
  series:
    [{
      name: 'Precipitação',
      type: 'column',
      yAxis: 0,
      data: [],
      dataGrouping: {
        units: groupingUnits
      },
      tooltip: {
        valueSuffix: ' mm',
        valueDecimals: 2,
      }
    },
    {
      name: 'Níveis',
      type: 'spline',
      yAxis: 1,
      data: [],
      dataGrouping: {
        units: groupingUnits
      },
      tooltip: {
        valueSuffix: 'm',
        valueDecimals: 2,
      }
  }]
}

const customHighcharts = Highcharts;
customHighcharts.setOptions({
  lang: {
    rangeSelectorFrom: 'De',
    rangeSelectorTo: 'Até',
    rangeSelectorZoom: 'Período',
    shortMonths: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    weekdays: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
  },
});

export const MarkerWithPopup = ({ accumulated, latitude, longitude, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const onClick = async () => {
    setIsLoading(true);
    try {
      const res = await getStation(props.institute, props.id);
      const precipitation = [];
      const river_level = [];
      _.map(res, (opt) => {
        if (props.institute == ANA_STATION) {
          precipitation.push([
            Number(moment(opt.date).format('x')),
            opt.precipitation,
          ]);
          river_level.push([
            Number(moment(opt.date).format('x')),
            Number((opt.river_level / 100).toFixed(3)),
          ]);
        }

        if (props.institute == SEAPI_STATION) {
          precipitation.push([
            Number(moment(opt.date).format('x')),
            opt.prec_1h,
          ]);
          river_level.push([
            Number(moment(opt.date).format('x')),
            Number((opt.river_level / 100).toFixed(3)),
          ]);
        }

      });
      let newData = _.clone(hcHelper);
      newData.title.text = props.name;
      newData.series[0].data = precipitation;
      newData.series[1].data = river_level;
      setData(newData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(true);
    }
  }
  
  const renderMarkerIcon = (accumulated) => {
    const label = accumulated ? accumulated : 0;
    return new L.divIcon({
      className: "a",
      iconSize: [32, 32],
      html: `<div class="markerPin"><p>${label}</p></div>`
    });
  }
  const renderDetails = () => {
    if (props.institute == ANA_STATION) {
    const operator = props.operator ? props.operator.split('-')[1] : '';
    const city = props.city ? props.city.split('-')[0] : '';
    return (
      <div className="stationsDetails">
        <span> Responsável: {operator} </span>
        <span> Rio: {capitalize(props.river_name)} </span>
        <span> Município: {capitalize(city)} </span>
        <span> Lat: {latitude} </span> 
        <span> Long: {longitude} </span>
      </div>
    )
    } 
    if (props.institute == SEAPI_STATION) {
      const operator = SEAPI_STATION.toUpperCase();
      const city = props.name ? props.name : '';
      return (
        <div className="stationsDetails">
          <span> Responsável: {operator} </span>
          <span> Rio: - </span>
          <span> Município: {capitalize(city)} </span>
          <span> Lat: {latitude} </span> 
          <span> Long: {longitude} </span>
        </div>
      )
      } 

  }
  const position = {
    lat: latitude,
    lng: longitude,
  };

  const icon = renderMarkerIcon(accumulated);
  const Icon = <Iconable type="loading" className="icon" spin />;
  return (
  <Marker position={position} icon={icon} onClick={onClick}>
    <Popup autoPanPaddingTopLeft={[5, 100]} maxWidth={1000}>
      <If test={isLoading}>
        <div className="loadingInfo">
          <Spin indicator={Icon} tip="Carregando..." size="large" />
        </div>
      </If>
      <If test={!isLoading}>
        <If test={error}>
        <div className="loadingError">
          Sem Dados
        </div>
        </If>
        <If test={!error}>
          <div className="loadingSuccess">
            <HighchartsReact
              highcharts={customHighcharts}
              constructorType={'stockChart'}
              options={data}
            />
            {renderDetails()}
          </div>
        </If>
      </If>
    </Popup>
  </Marker>
)}

MarkerWithPopup.propTypes = {
  accumulated: PropTypes.number,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  content: PropTypes.string,
  position: PropTypes.array,
  name: PropTypes.string,
  operator: PropTypes.string,
  city: PropTypes.string,
  river_name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  institute: PropTypes.string,
}
