import React from 'react'
import { If } from '@/components'
import { Link } from 'react-router-dom'
import { Document, Page } from 'react-pdf/dist/entry.webpack'
import './index.styl'

export const PdfPreview = ({ route, pdf }) => {
  return (
    <div className="PdfPreview">
      <Link to={`/${route}/${pdf.id}`}>
        <div className="PdfPreview__container">
          <div className="PdfPreview__container__document">
            <Document
              file={`data:application/pdf;base64,${pdf.base64}`}
              loading="Carregando Documento!"
              renderMode="canvas"
            >
              <Page pageNumber={1} width={350} scale={2} />
              <div className="PdfPreview__meta">
                <span>{pdf.titulo}</span>
              </div>
            </Document>
          </div>
        </div>
      </Link>
    </div>
  )
}
