import React from 'react';
import { Spin, Icon as Iconable } from 'antd';
import './index.styl';

const Icon = <Iconable type="loading" className="icon" spin />

export const Loading = () => (
  <div className="overlay">
    <Spin indicator={Icon} size="large" />
  </div>
)
