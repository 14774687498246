import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { Row, Col } from 'antd';
import './index.styl';

const NewsSingleView = ({ data }) => {
  const news = _.get(data, 'data[0]', '');
  return (
    <Row justify="center" type="flex">
      <Col xs={24} lg={16}>
        <div className="NewsSingle__wrapper">
          <h2> {news.titulo}</h2>
          <p> 
            Data:<span> {moment(news.data).format('DD/MM/YYYY')} </span> | Fonte: <span> {news.autor} </span>
            </p>
          <div className="NewsSingle__image">
            <img src={`data:image/png;base64, ${news.base64}`} />
          </div>
          <div dangerouslySetInnerHTML={{ __html: news.corpo }} />
        </div>
      </Col>
    </Row>
  )
}

NewsSingleView.propTypes = {
  data: PropTypes.object,
}

export default NewsSingleView;
