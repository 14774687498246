import React from 'react';
import { Row, Col, Typography } from 'antd';
import logoDefesaCivil from '@/resources/defesacivil-rs.png';
import logoFecoergs from '@/resources/fecoergs.jpg';
import './index.styl';

const { Title } = Typography;

const Sponsors = () => {
  return (
    <Row id="parceiros" className="Row Sponsors" type="flex" justify="center">
      <Col xs={16}>
        <Col xs={24}>
          <Title className="Sponsors__title" level={2}>
            Parceiros da Sala
          </Title>
        </Col>
        <Col className="Sponsors__logos" xs={24} sm={8} >
          <img src={logoDefesaCivil} />
        </Col>
        <Col className="Sponsors__details" xs={24} sm={16} >
          A Defesa Civil do Estado do Rio Grande do Sul é o principal parceiro da Sala de Situação.
          Os boletins gerados pela Sala foram desenvolvidos, tanto na forma (layout) como no conteúdo,
          de maneira a atender a demanda e as necessidades deste Órgão.
          A articulação entre a Sala de Situação e a Defesa Civil é fundamental para a tomada de decisão,
          no que diz respeito à prevenção e ações decorrentes da previsão de ocorrência de eventos hidrometeorológicos aqui no Estado.
        </Col>
        <Col className="Sponsors__logos" xs={24} sm={8} >
          <img src={logoFecoergs} />
        </Col>
        <Col className="Sponsors__details" xs={24} sm={16} >
          A distribuição e geração de energia também faz parte do dia a dia da Sala de Situação.
          Os boletins gerados são enviados duas vezes ao dia à FECOERGS.
          Com um olhar mais atento às condições de tempo, em especial, as chuvas fortes,
          descargas elétricas e ventos, a Sala de Situação faz o monitoramento a fim de alertar o
          Setor de possíveis transtornos decorrentes das intempéries tão comuns aqui no Rio Grande do Sul.
        </Col>
      </Col>
    </Row>
  )
}

export default Sponsors;
