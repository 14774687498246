import posed from 'react-pose'

export const rendering = Component =>
  posed(Component)({
    enter: {
      opacity: 1
    },
    exit: {
      opacity: 0
    }
  });
