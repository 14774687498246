import React, { useContext, useState, useEffect } from 'react';
import { CollapsedContext } from '@/contexts';
import { Loading, If, PdfView, Footer } from '@/components';

import { getAlerts } from '@/services/salaDeSituacaoApi';

export const AlertsSingle = (props) => {
  const { collapsed } = useContext(CollapsedContext);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const response = await getAlerts({ id: props.match.params.id });
      setData(response);
      setIsLoading(false)
    }
    fetchData();
  }, []);

  return (
    <div
    style={{ width: `calc(100% - ${collapsed ? 80 : 230}px)`, position: 'relative' }}
    >
      <If test={isLoading}>
        <Loading key="loading" />
      </If>
      <If test={!isLoading}>
        <PdfView route={'alertas'} data={data} />
        <Footer />
      </If>
    </div>
  )
}
