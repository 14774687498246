import React, { useContext, useState, useEffect } from 'react';
import { CollapsedContext } from '@/contexts';
import { getNews } from '@/services/salaDeSituacaoApi';
import NewsView from './components/NewsView';
import { Loading, If, Footer } from '@/components'; 

import './index.styl';

export function News() {
  const { collapsed } = useContext(CollapsedContext);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    async function fetchData() {
      const response = await getNews({});
      setData(response);
      setIsLoading(false)
    }
    fetchData();
  }, []);

  const onChange = async (page) => {
    setIsLoading(true)
    setCurrentPage(page);
    const response = await getNews({ page });
    setData(response);
    setIsLoading(false)
  }

  return(
    <div
    style={{ width: `calc(100% - ${collapsed ? 80 : 230}px)`, position: 'relative' }}
    >
      <If test={isLoading}> 
        <Loading key="loading" />
      </If>
      <If test={!isLoading}>
        <NewsView data={data} onChange={onChange} currentPage={currentPage} />
        <Footer />
      </If>
    </div>
  )
}