import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import basins from '@/resources/basins.json';

export const ShapesContext = createContext({});

export function ShapesProvider({ children }) {
  const [data, setData] = useState({});

  useEffect(() => setData(basins), []);

  return (
    <ShapesContext.Provider value={data}>{children}</ShapesContext.Provider>
  )
}

ShapesProvider.propTypes = {
  children: PropTypes.node.isRequired
}
