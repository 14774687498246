import React from 'react';
import _ from 'lodash';
import { Row, Col, Typography } from 'antd';
import NewsShowcase from '../NewsShowcase';
import NewsOthers from '../NewsOthers';
import { Pagination } from 'antd';
import './index.styl';

const { Title } = Typography;



const NewsView = ({ data, onChange, currentPage }) => {
  return (
    <div className="News__wrapper">
      <Title>Últimas notícias</Title>
      <NewsShowcase showcaseData={data.data} />
      <Pagination defaultCurrent={1} total={data.pagination.total} defaultPageSize={1} onChange={onChange} current={currentPage} />
    </div>
  )
}

export default NewsView;
