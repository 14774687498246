import React from 'react';
import PropTypes from 'prop-types';
import { Icon, If } from '@/components';
import './index.styl';

const StationsButton = ({ showMarkers, onClick }) => {
  return(
    <div>
      <If test={showMarkers}>
        <div className="stations-legend">
          <div className="markerPin">
            <p>10</p>
          </div>
          <span>Chuva nas últimas 24h em mm </span>
        </div>
      </If>
      <div className="stations-button" onClick={onClick}>
      <Icon name="stations" className="icon" />
        <p>Estações  Hidrometeorológicas</p>
      </div>
    </div>
  )
}

StationsButton.propTypes = {
  showMarkers: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default StationsButton