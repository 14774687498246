import React, { useContext } from 'react';
import { CollapsedContext } from '@/contexts';

import DataView from './components/DataView';
import  { Footer }  from '@/components';


export function Data() {
  const { collapsed } = useContext(CollapsedContext);
  return(
    <div
    style={{ width: `calc(100% - ${collapsed ? 80 : 230}px)` }}
    className="chart"
    >
      <DataView />
      <Footer />
    </div>
  )
}