/**
 * Nome das fontes definidos pelo site abaixo.
 * @see http://chir.ag/projects/name-that-color/
 */
export default {
  mountain: '#1dbe72',
  black: '#000',
  white: '#fff',
  cruise: '#b7edd4',
  charade: '#2d323e',
  gray: '#69696a',
  picton: '#35baf6',
  athens: '#f7f8fa'
}
