import React from 'react';
import PropTypes from 'prop-types';
import './index.styl';

const TabHeader = ({ title, description, setMapLayer, type }) => {
  return (
    <div className="tab-header" onClick={() => setMapLayer(type)}>
      <p className="title">{title}</p>
      <p className="description">{description}</p>
    </div>
  )
}

TabHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  setMapLayer: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
}

export default TabHeader
