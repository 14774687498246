import React from 'react';

export const If = ({test, children}) => {

  if (test && children) {
    const content = typeof children === 'string' ? <p>{children}</p> : children;
    return content;
  }

  return null;
}