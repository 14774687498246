import React, { useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'antd';
import { If, Loading } from '@/components';
import { Document, Page } from 'react-pdf';
import './index.styl'

export const PdfView = ({ route, data }) => {
  const [numPages, setNumPages] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsLoading(false);
  }

  const pdf = _.head(data.data);
  const pdfData = `data:application/pdf;base64,${pdf.base64}`

  return (
      <div className="PdfView">
        <div className="PdfView__header">
          <div>
            <Link to={`/${route}`}>
              <Button type="primary">
                <Icon type="left" /> Voltar
              </Button>
            </Link>
          </div>
          <div>
          <a href={pdfData} title='Download pdf document' download={pdf.titulo}>
            <Button type="primary" icon="download">Download</Button>
          </a>
          </div>

        </div>

        <If test={isLoading}>
          <Loading />
        </If>

          <div className="PdfView__container">
            <div className="PdfView__container__document">
              <Document
                file={pdfData}
                onLoadSuccess={onDocumentLoadSuccess}
              >
              <If test={!isLoading}>
                {
                  Array.from(
                    new Array(numPages),
                    (__, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        width={900}
                      />
                    ),
                  )
                }
              </If>
              </Document>
            </div>
          </div>
      </div>
    );
}
