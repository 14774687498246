import React from 'react'
import { Layout, Row, Col, Typography, Pagination } from 'antd'
import _ from 'lodash'
import { If, PdfPreview } from '@/components'
import './index.styl'

const { Content } = Layout
const { Title } = Typography

const AlertsView = ({ data, onChangePage }) => {
  const renderAlerts = () => {
    return _.map(data.data, alert => {
      return (
        <Col xs={24} lg={12} xl={8} className="Alerts__preview" key={alert.id}>
          <PdfPreview route={'alertas'} pdf={alert} />
        </Col>
      )
    })
  }

  return (
    <Layout>
      <Content className="Alerts__content">
        <Row className="Row" type="flex" justify="center">
          <Title className="Alerts__title">
            Avisos emitidos pela Sala de Situação
          </Title>
          <Col className="Alerts__subtitle" xs={20} lg={16}>
            <p>
              Os Avisos Hidrometeorológicos são enviados diretamente à defesa
              civil em caso de previsão de eventos adversos.
            </p>
          </Col>
        </Row>
        <Row gutter={8}>{renderAlerts()}</Row>
        <If test={data.pagination}>
          <Pagination
            defaultCurrent={1}
            total={data.pagination.total}
            defaultPageSize={1}
            onChange={onChangePage}
            current={data.pagination.current}
          />
        </If>
      </Content>
    </Layout>
  )
}

export default AlertsView
