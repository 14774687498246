import React from 'react';
import './index.styl';

export const Youtube = ({ video, autoplay }) => {
  const videoSrc = `https://www.youtube.com/embed/${video}?autoplay=${autoplay}`
  return (
    <div className="Youtube__wrapper">
      <iframe
        className="Youtube__player"
        type="text/html"
        src={videoSrc}
        frameBorder="0"
      />
    </div>
  );
}