import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tabs } from 'antd';
import { TypeContext } from '@/contexts';
import { PROVIDERS } from '@/services/layers';
import TabHeader from '../TabHeader';
import TabButtons from '../TabButtons';
import './index.styl';

const TabPane = Tabs.TabPane;

const getDateFromDayAhead = (day) => moment().add(day, 'day').format('DD/MM');

const TabsContainer = ({ setMapLayer }) => {
  const { type, setType } = useContext(TypeContext);

  const findMapLayer = ({ day, provider, type = null }) => {
    setMapLayer({ day, type, provider })
    type ? setType(type) : null
  }

  const renderForecastTabHeader = (day) => {
    return <TabHeader
      onClick={() =>findMapLayer({ day, type, provider: PROVIDERS.somar })}
      title={day === 1 ? 'Amanhã' : getDateFromDayAhead(day)}
      description="Previsão"
    />
  }

  return (
    <div className="tabs-container-forecast">
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <TabHeader
              onClick={() =>findMapLayer({ day: 0, provider: PROVIDERS.wublast })}
              title="Condição atual"
              description="Imagens de satélite"
            />
          }
          key="1"
        />

        <TabPane 
          tab={renderForecastTabHeader(1)}
          key="2"
        >
          <TabButtons findMapLayer={findMapLayer} day={1} />
        </TabPane>

        <TabPane
          tab={renderForecastTabHeader(2)}
          key="3"
        >
          <TabButtons findMapLayer={findMapLayer} day={2} />
        </TabPane>

        <TabPane
          tab={renderForecastTabHeader(3)}
          key="4"
        >
          <TabButtons findMapLayer={findMapLayer} day={3} />
        </TabPane>
      </Tabs>
    </div>
  )
}

TabsContainer.propTypes = {
  setMapLayer: PropTypes.func.isRequired
}

export default TabsContainer
