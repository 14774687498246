export const isEmpty = object => Object.entries(object).length === 0

export const isEmptyArray = array => array.length === 0

export const capitalize = (str) => {
  const arr = str.split(' ');
  const capitalized = [];
  arr.map(s => {
    if (s[0]) {
      capitalized.push(s[0].toUpperCase() + s.slice(1).toLowerCase());
    }
  });
  return capitalized.join(' ');
}