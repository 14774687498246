import React, { memo } from 'react';
import PropTypes from 'prop-types';
import captionSatellite from '@/resources/captions/caption_satellite.png';
import captionPrec from '@/resources/captions/caption_chuva.png';
import captionPrecPentada from '@/resources/captions/caption_chuva_pentada.png';
import captionTemp from '@/resources/captions/caption_temp.png';
import captionFrost from '@/resources/captions/caption_geada.png';
import './index.styl';

const captions = {
  satellite: captionSatellite,
  tmin: captionTemp,
  tmax: captionTemp,
  prec: captionPrec,
  precPentada: captionPrecPentada,
  frost: captionFrost,
};

export const MapCaption = memo(({ caption }) => {
  if (!captions[caption]) return null

  return (
    <div className="map-caption">
      <img alt="Map caption" src={captions[caption]} />
    </div>
  )
});

MapCaption.propTypes = {
  caption: PropTypes.string.isRequired
};
