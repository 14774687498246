import React, { useState, useEffect } from 'react';
import { Table, Input, Button } from 'antd';
import { Loading, If } from '@/components'
import { getStations, BASE_URL } from '@/services/salaDeSituacaoApi';
import './index.styl';

const Search = Input.Search;
const tableColumns = [
  {
    title: 'Código',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Estação',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Responsável/Operadora',
    dataIndex: 'operator',
    key: 'operator'
  },
  {
    title: 'Tipo de Estação',
    dataIndex: 'origin',
    key: 'origin'
  },
  {
    title: 'Município',
    dataIndex: 'city',
    key: 'city'
  },
  {
    title: 'Rio',
    dataIndex: 'river_name',
    key: 'river_name'
  },
  {
    title: 'Bacia Hidrográfica',
    dataIndex: 'basin_name',
    key: 'basin_name'
  },
  {
    title: 'Download Histórico Completo',
    dataIndex: '',
    key: 'x', 
    render: (obj) => <Button type="primary" href={`${BASE_URL}/station/${obj.institute}/sheet/${obj.id}`}  target="_blank" icon="download">Download</Button>
  },
];


export default function DataView() {
  const [data, setData] = useState();
  const [datasource, setDatasource] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const response = await getStations();
      setData(response);
      setDatasource(response);
      setIsLoading(false)
    }
    fetchData();
  }, []);

  const onSearch = search => {
    search = search.toLowerCase();
    const filteredEvents = datasource.filter(({ id, name, operator, city, sponsor, river_name }) => {
      id = id ? id.toString() : [];
      name = name ? name.toLowerCase() : [];
      operator = operator ? operator.toLowerCase() : [];
      city = city ? city.toLowerCase() : [];
      sponsor = sponsor ? sponsor.toLowerCase() : [];
      river_name =  river_name ? river_name.toLowerCase() : [];
      return id.includes(search) || name.includes(search) || operator.includes(search) || city.includes(search) || sponsor.includes(search) || river_name.includes(search);
    });

    setData(filteredEvents);
  }
  return (
    <div className="content">
      <div className="searchWrapper">
        <Search
          size="large"
          placeholder="Busque por: nome da estação, código, município, rio ou bacia hidrográfica"
          onSearch={onSearch}
          enterButton="Buscar"
        />
      </div>
      <div className="dataView__content">
        <If test={isLoading}>
          <Loading key="loading" />
        </If>
        <If test={!isLoading}>
          <Table
            className="dataView__table"
            align="center"
            dataSource={data}
            columns={tableColumns}
          />
        </If>
      </div>
    </div>
  )
}