import React from 'react';
import { Row, Col, Typography, Icon } from 'antd';
import { Youtube } from '@/components';
import operation from '@/resources/operacao_sema.jpg';
import divider from '@/resources/divider.png';
import './index.styl';

const { Title } = Typography;

const About = () => {
  return (
    <Row type="flex" gutter={24} justify="center" className="About__Row--highlights">
      <Col xs={20}>
        <Title className="About__hightlights--title">
          Informações sobre a Sala, Equipes e Parceiros
        </Title>
      </Col>
      <Col className="About__youtube" span={8} xs={20} md={10} xl={8}>
        <Youtube video="VPSzlzwBmxU" autoplay="0" />
      </Col>
      <Col className="Col About__hightlights--content" span={8} xs={20} md={10} xl={10}>
        <p className="About__hightlights--minor">A Sala de Situação da SEMA funciona como um centro de prevenção, 
        monitoramento e mapeamento de eventos hidrológicos, integrado à Agência Nacional de Águas.</p>
        <p className="About__hightlights--minor">Sua missão é contribuir para a gestão integrada de bacias hidrográficas
        e identificação de ocorrência de eventos extremos, mediante acompanhamento
        e previsão das condições hidrometeorológicas, com o fim de minimizar os efeitos adversos de secas e inundações.</p>
      </Col>
      <Col className="About__divider" xs={24}>
        <img src={divider} />
      </Col>
      <Row type="flex" gutter={64} justify="center" className="About__Row--objectives">
        <Col className="Col About__objectives" xs={24} md={16} lg={14} xl={12} xxl={10}>
          <p className="About__objectives--title">
          A Sala de Situação SEMA RS é vinculada à Divisão de Meteorologia, 
          Mudanças Climáticas e Eventos Críticos, do Departamento de Gestão de Recursos Hídricos e Saneamento (DRH),
          e tem os seguintes objetivos:
          </p>
          <div className="About__objectives--bullets">
            <p><Icon style={{ color: '#35baf6'}} type="check" /> Realizar o monitoramento hidrometeorológico para o estado do RS</p>
            <p><Icon style={{ color: '#35baf6'}} type="check" /> Sistema de previsão de eventos hidrometeorológico extremos</p>
            <p><Icon style={{ color: '#35baf6'}} type="check" /> Estudos dos pontos mais recorrentes de inundação</p>
            <p><Icon style={{ color: '#35baf6'}} type="check" /> Estabelecimento de limiares de alerta de eventos extremos</p>
          </div>
        </Col>
        <Col className="Col About__objectives--image" xs={24} md={6} lg={8} xl={6} xxl={5}>
          <img src={operation} />
        </Col>
      </Row>
    </Row>
  )
}

export default About;
