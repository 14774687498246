import axios from 'axios';
import _ from 'lodash';
import qs from 'qs';
import stations from '@/resources/stations.json';

export const ANA_STATION = 'ana';
export const SEAPI_STATION = 'ana';
export const BASE_URL = 'https://saladesituacao.rs.gov.br/api';

const stationsType = {
  [ANA_STATION]: 'ana',
  [SEAPI_STATION]: 'ana',
}

export const getStations = async (defaultStation = false) => {
  try {
    const seapiRes = await axios.get(`${BASE_URL}/station/${SEAPI_STATION}`);
    const anaRes = await axios.get(`${BASE_URL}/station/${ANA_STATION}`);
    const response = defaultStation
      ? [...stations]
      : [...anaRes.data, ...seapiRes.data];
    return _.sortBy(response, 'name');
  } catch (e) {
    throw new Error('Failed to fetch stations data');
  }
}

export const getStation = async (type, id) => {
  try {
    const response = await axios.get(`${BASE_URL}/station/${stationsType[type]}/${id}`);
    return response.data;
  } catch (e) {
    throw new Error('Failed to fetch station data');
  }
}

export const getNews = async (params) => {
  const {
    category = null,
    id = null,
    perPage = 6,
    page = 1,
  } = params;
  const pathWithQuery = mountPath(category) + qs.stringify({ page, perPage, id}, { addQueryPrefix: true, skipNulls: true });
  const url = `${BASE_URL}/news${pathWithQuery}`
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (e) {
    throw new Error('Failed to fetch news data');
  }
}

export const getBulletins = async (params) => {
  const {
    type = null,
    id = null,
    perPage = 3,
    page = 1,
    month = null,
    year = null,
  } = params;
  const pathWithQuery = mountPath(type) + qs.stringify({ page, perPage, id, month, year }, { addQueryPrefix: true, skipNulls: true });
  const url = `${BASE_URL}/bulletins${pathWithQuery}`
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (e) {
    throw new Error('Failed to fetch bulletins data');
  }
}

export const getAlerts = async (params) => {
  const {
    id = null,
    perPage = 6,
    page = 1,
  } = params;
  const pathWithQuery = qs.stringify({ page, perPage, id }, { addQueryPrefix: true, skipNulls: true });
  const url = `${BASE_URL}/alerts${pathWithQuery}`
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (e) {
    throw new Error('Failed to fetch alerts data');
  }
}

export const getSpotlight = async () => {
  const url = `${BASE_URL}/spotlight`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (e) {
    throw new Error('Failed to fetch spotlight data');
  }
}
const mountPath = string => string ? `/${string}` : '';
