import React, { useState, useContext } from 'react';
import { Menu, Button } from 'antd';
import { NavLink as Link } from 'react-router-dom';
import { Icon, Head } from '@/components';
import { CollapsedContext } from '@/contexts';
import './index.styl';

export function Sidebar() {
  const { collapsed, setCollapsed } = useContext(CollapsedContext);
  const [agro, setAgro] = useState(false);
  const toggleCollapsed = () => setCollapsed(!collapsed);
  const toggleAgro = () => setAgro(!agro);

  return (
    <div className="sidebar">
      <div className={`container ${collapsed ? 'collapsed' : ''}`}>
        <Head collapsed={collapsed} toggleCollapsed={toggleCollapsed} />

        <Menu
          className="menu"
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          mode="inline"
          inlineCollapsed={collapsed}
        >
          <Menu.Item className="item" key="1">
            <Link className="center" to="/">
              <Icon name="map" className="icon" />
              <span className="description">
                Mapa de Previsão e Monitoramento
              </span>
            </Link>
          </Menu.Item>

          {!collapsed ? (
            <Menu.Item onClick={toggleAgro} className="item agro" key="2">
              <Link
                activeClassName="agroActive"
                className="agroContainer"
                to="/agro/mapa"
              >
                <Icon name="tractor" className="icon" />
                <span className="description">Dados Agroclimáticos</span>
              </Link>

              <div className="content">
                <Link activeClassName="agroActive" to="/agro/mapa">
                  <Button className="button" type="secondary">
                    Mapas
                  </Button>
                </Link>

                <Link activeClassName="agroActive" to="/agro/grafico">
                  <Button className="button" type="secondary">
                    Gráficos
                  </Button>
                </Link>
              </div>
            </Menu.Item>
          ) : (
            <Menu.Item className="item agro">
              <Link activeClassName="agroActive" to="/agro/mapa">
                <Icon name="tractor" className="icon" />
                <span className="description">Dados Agroclimáticos</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item className="item" key="3">
            <Link className="center" to="/boletins">
              <Icon name="boletins" className="icon" />
              <span className="description"> Boletins </span>
            </Link>
          </Menu.Item>
          <Menu.Item className="item" key="4">
            <Link className="center" to="/alertas">
              <Icon name="alerts" className="icon" />
              <span className="description"> Histórico de Avisos </span>
            </Link>
          </Menu.Item>
          <Menu.Item className="item" key="5">
            <Link className="center" to="/noticias">
              <Icon name="news" className="icon" />
              <span className="description"> Noticias </span>
            </Link>
          </Menu.Item>
          <Menu.Item className="item" key="6">
            <Link className="center" to="/dados">
              <Icon name="data" className="icon" />
              <span className="description"> Dados </span>
            </Link>
          </Menu.Item>
          <Menu.Item className="item" key="7">
            <Link className="center" to="/informacoes">
              <Icon name="info" className="icon" />
              <span className="description"> Informações </span>
            </Link>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  )
}
