import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Row, Col, Icon } from 'antd';
import logos from '@/resources/footer-logos.jpg';
import logoSema from '@/resources/logo-white.png';
import './index.styl';

export const Footer = () => {
  return (
    <div className="footer">
      <div className="info">
        <Row type="flex" justify="center">
          <Col span={6} pull={6}>
            <img className="logo" alt="Logo Sema" src={logoSema} />
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col className="address" span={6}>
            <span>Rua Gen. Andrade Neves , 106</span>
            <br />
            <span>11° Andar</span>
            <br />
            <span>Centro Histórico - Porto Alegre/RS</span>
            <br />
            <p>(51) 3221-7098</p>
            <p>sala-situacao@sema.rs.gov.br</p>
          </Col>
          <Col span={6}>
            <ul>
              <li>
                <Link to="/">Página Inicial</Link>
              </li>
              <li>
                <Link to="/informacoes">Sobre</Link>
              </li>
              <li>
                <Link to="">Monitoramento</Link>
              </li>
              <li>
                <Link to="">Previsão do tempo</Link>
              </li>
              <li>
                <Link to="/boletins">Boletins</Link>
              </li>
            </ul>
          </Col>
          <Col span={6}>
            <ul>
              <li>
                <Link to="/noticias">Notícias</Link>
              </li>
              <li>
                <Link to="/informacoes#equipe">Equipe</Link>
              </li>
              <li>
                <Link to="/informacoes#parceiros">Parceiros e Clientes</Link>
              </li>
              <li>
                <Link to="/agro/grafico">Dados agrícolas</Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={6} pull={6}>
            <div className="icons">
              <a href="https://twitter.com/salasituacao_rs" target="_blank" rel="noopener noreferrer">
                <Icon className="twitter" type="twitter" />
              </a>
              <a href="https://www.facebook.com/salasituacaoSEMA.RS/" target="_blank" rel="noopener noreferrer">
                <Icon className="facebook" type="facebook" />
              </a>
            </div>
          </Col>
        </Row>
      </div>
      <div className="logos">
        <img alt="Logos" src={logos} />
      </div>
    </div>
  )
}
