import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import moment from 'moment';
import TabHeader from '../TabHeader';
import './index.styl';

const TabPane = Tabs.TabPane;

const day0 = moment().format('DD/MM');
const day5 = moment()
  .add(5, 'day')
  .format('DD/MM');

const TabsContainer = ({ setMapLayer }) => {
  return (
    <div className="tabs-container-mapeable">
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <TabHeader
              title="Pêntada Chuva"
              description={`${day0} a ${day5}`}
              setMapLayer={setMapLayer}
              type={'precPentada'}
            />
          }
          key="1"
        />
        <TabPane
          tab={
            <TabHeader
              title="Pêntada Tmin"
              description={`${day0} a ${day5}`}
              setMapLayer={setMapLayer}
              type={'tmin'}
            />
          }
          key="2"
        />
        <TabPane
          tab={
            <TabHeader
              title="Pêntada Tmax"
              description={`${day0} a ${day5}`}
              setMapLayer={setMapLayer}
              type={'tmax'}
            />
          }
          key="3"
        />
        <TabPane
          tab={
            <TabHeader
              title="Geada"
              description={`${day0} a ${day5}`}
              setMapLayer={setMapLayer}
              type={'frost'}
            />
          }
          key="4"
        />
      </Tabs>
    </div>
  )
}

TabsContainer.propTypes = {
  setMapLayer: PropTypes.func.isRequired
}

export default TabsContainer
