// eslint-disable-next-line no-undef
//const URI = process.env.NIMBUS_URI
const URI = 'https://nimbus.climatempo.io'
const KEY = 'NIAg8jxVyP2bH9JdfaEEBVZiUAy2RC34IvlyI4Ob'

const toJSON = raw => raw.json()

const defaultConfig = {
  headers: {
    'x-api-key': KEY
  }
}

export const fetchHydricBalance = ({ lat, lon, period }) => {
  const { initial, final } = period

  return fetch(
    `${URI}/agriculture/waterbalance?latitude=${lat}&longitude=${lon}&initi_date=${initial}&final_date=${final}&gen=CtAn&soils=Ag`,
    defaultConfig
  ).then(toJSON)
}

export const fetchObservedDaily = ({ lat, lon, period }) => {
  const { initial, final } = period

  return fetch(
    `${URI}/observed/daily?latitude=${lat}&longitude=${lon}&initi_date=${initial}&final_date=${final}`,
    defaultConfig
  ).then(toJSON)
}
