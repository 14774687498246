import React, { useState } from 'react';
import moment from 'moment';
import uuid from 'uuid/v4';
import { LineChart, XAxis, YAxis, Tooltip, Line, CartesianGrid } from 'recharts';
import Content from '@/components/ChartContent';

const tableColumns = [
  {
    title: 'Estação',
    dataIndex: 'station',
    key: 'station'
  },
  {
    title: 'Dia',
    dataIndex: 'week',
    key: 'week'
  },
  {
    title: 'Radiação',
    dataIndex: 'value',
    key: 'value'
  }
];

export default function Radiation() {
  const [data, setData] = useState([]);

  const formatter = (data) => {
    const aggregate = data.points.observed.mean_downward_short_wave_radiaton.map(
      (radiation, iterate) => ({
        week: data.periods[iterate],
        value: radiation.toFixed(0)
      })
    );

    setData(aggregate)
  }

  const tableSource = data.map(({ week, value }) => ({
    week: moment(week).format('DD MMM'),
    value: `${value} kJ/m²`,
    key: uuid()
  }));

  const tickFormatter = tick => moment(tick).format('DD.MMM');

  return (
    <Content
      tableColumns={tableColumns}
      chartSource={data}
      tableSource={tableSource}
      getCallable={formatter}
    >
      <LineChart data={data} margin={{ top: 5, right: 0, left: 0, bottom: 0 }}>
        <XAxis dataKey="week" tickFormatter={tickFormatter} />
        <YAxis tickFormatter={tick => `${tick} kJ/m²`} domain={[0,7000]} />

        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Line
          type="monotone"
          name="Radiação"
          dataKey="value"
          fillOpacity={1}
          stroke="#1dbe72"
          fill="#1dbe72"
        />
      </LineChart>
    </Content>
  )
}
