import React, { useContext, useEffect } from 'react';
import { Tabs } from 'antd';
import { CollapsedContext, AgroContext, LoadingContext } from '@/contexts';
import { Footer } from '@/components';
import HybridBalance from './components/HybridBalance';
import Radiation from './components/Radiation';
import RelativeHumidity from './components/RelativeHumidity';
import Evapotranspiration from './components/Evapotranspiration';
//import TotalWetting from './components/TotalWetting';
import { fetchObservedDaily, fetchHydricBalance } from '@/services/nimbus';
import './index.styl';

const Tab = Tabs.TabPane;

export function Chart() {
  const { collapsed } = useContext(CollapsedContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { data, setData } = useContext(AgroContext);

  const isEmptyState = _.isEmpty(data.period) || _.isEmpty(data.station);

  useEffect(() => {
    async function getObservedDaily(lat, lon, period) {
      const oberservedDaily = await fetchObservedDaily({ lat, lon, period });
      const hydricBalance = await fetchHydricBalance({ lat, lon, period });
      const dataFromApi = {...oberservedDaily, ...hydricBalance}
      setData({...data, data: dataFromApi, shouldFetchNewData: false })
     }
    if (!isEmptyState && data.shouldFetchNewData) {
      setIsLoading(true)
      getObservedDaily(data.station.latitude, data.station.longitude, data.period)
    }
  }, [data.period, data.station]);

  return (
    <div
      style={{ width: `calc(100% - ${collapsed ? 80 : 230}px)` }}
      className="chart"
    >
      <Tabs className="tabs" defaultActiveKey="1">
        <Tab className="tab" tab="Radiação" key="1">
          <Radiation />
        </Tab>
        <Tab className="tab" tab="Evapotranspiração" key="2">
          <Evapotranspiration />
        </Tab>
        <Tab className="tab" tab="Balanço hidrico" key="3">
          <HybridBalance />
        </Tab>
        {/*<Tab className="tab" tab="Molhamento total" key="4">
          <TotalWetting />
        </Tab>*/}
        <Tab className="tab" tab="Umidade relativa" key="4">
          <RelativeHumidity />
        </Tab>
      </Tabs>

      <Footer />
    </div>
  )
}
