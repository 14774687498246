import React from 'react'
import ReactDOM from 'react-dom'
import Router from './Router'
import 'moment/locale/pt-br';
import { ShapesProvider, MapBoundsProvider, TypeProvider, CollapsedProvider, LoadingProvider, AgroProvider  } from '@/contexts';

import './index.styl'

/**
 * @todo Deixar estado global horizontal
 */
ReactDOM.render(
    <CollapsedProvider>
      <LoadingProvider>
        <MapBoundsProvider>
          <TypeProvider>
            <ShapesProvider>
              <AgroProvider>
                <Router />
              </AgroProvider>
            </ShapesProvider>
          </TypeProvider>
        </MapBoundsProvider>
      </LoadingProvider>
    </CollapsedProvider>,
  document.getElementById('root')
)
