import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import logo from '@/resources/logo.png';
import logoWhenCollapsed from '@/resources/collapsed.png';
import './index.styl';

export const Head = memo(({ toggleCollapsed, collapsed }) => (
  <div className="head">
    <Icon
      className="toggleable"
      onClick={toggleCollapsed}
      type={collapsed ? 'menu-unfold' : 'menu-fold'}
    />
  <Link to="/">
    <img
        className="logo"
        src={collapsed ? logoWhenCollapsed : logo}
        alt="SEMA"
      />
  </Link>

    <p className="about">
      Monitoramento, prevenção e mapeamento de eventos hidrológicos
    </p>
  </div>
))

Head.propTypes = {
  toggleCollapsed: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired
}
