import React, { useContext, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spin, Icon } from 'antd';
import {
  Map as Mapeable,
  TileLayer,
  GeoJSON,
  ImageOverlay
} from 'react-leaflet';
import { If, MapMarkers } from '@/components';
import { ShapesContext, MapBoundsContext } from '@/contexts';
import './../index.styl';

export function Map({ children, layerUrl, overlayBounds, showMarkers }) {
  const mapeable = useRef(null);
  const shapes = useContext(ShapesContext);
  const { mapBounds, setMapBounds } = useContext(MapBoundsContext);
  const [loading, setLoading] = useState(true);
  const center = {
    lat: -30.0447839,
    lng: -52.9758234
  };

  useEffect(() => {
    const map = mapeable.current

    if (map) {
      const getBounds = map.leafletElement.getBounds()

      const { lat: maxLat, lng: maxLng } = getBounds.getNorthEast()
      const { lat: minLat, lng: minLng } = getBounds.getSouthWest()

      setMapBounds([[maxLat, maxLng], [minLat, minLng]])
    }
  }, [])

  useEffect(() => {
    setLoading(true)

    let img = new Image()
    img.onload = () => setLoading(false)
    img.src = layerUrl
  }, [layerUrl])

  const shapeStyle = {
    weight: 1
  };
  const loadingIcon = <Icon type="loading" style={{ fontSize: 36 }} spin />;
  return (
    <>
      <If test={loading}>
        <Spin indicator={loadingIcon} tip="Carregando..." size="large" />
      </If>
      <Mapeable
        className="map"
        ref={mapeable}
        center={center}
        zoom={7}
        minZoom={7}
        maxBounds={mapBounds}
        maxBoundsViscosity={1.0}
        attributionControl
        zoomControl={false}
        scrollWheelZoom
        dragging
        animate
        preferCanvas
        easeLinearity={0.35}
      >
        <If test={showMarkers}>
          <MapMarkers />
        </If>
        <If test={layerUrl && !showMarkers}>
          <ImageOverlay
            interactive
            opacity={0.5345325}
            url={layerUrl}
            bounds={overlayBounds}
          />
        </If>

        <TileLayer url="https://api.mapbox.com/styles/v1/mapbox/streets-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmFvMTU1IiwiYSI6ImNsc2Jzd3VzeDBocXgyaW82Z3B3encyZWQifQ.xPdIqrD9f5I-LLZDq9AkWg" />
        <If test={shapes}>
          <GeoJSON data={shapes} style={shapeStyle} />
        </If>

        {children}
      </Mapeable>
    </>
  )
}

Map.propTypes = {
  layerUrl: PropTypes.string,
  overlayBounds: PropTypes.array,
  showMarkers: PropTypes.bool,
  children: PropTypes.node
}

Map.defaultProps = {
  layerUrl: null,
  showMarkers: false,
  overlayBounds: [
    [-22.55235365216548, -43.70288085937501],
    [-37.542762387234845, -63.13916015625001]
  ]
}
