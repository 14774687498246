import React, { useContext, useState, useEffect } from 'react';
import { CollapsedContext } from '@/contexts';
import { Loading, If, Footer } from '@/components';
import AlertsView from './components/AlertsView';

import { getAlerts } from '@/services/salaDeSituacaoApi';

export function Alerts(props) {
  const { collapsed } = useContext(CollapsedContext);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const response = await getAlerts({});
      setData(response);
      setIsLoading(false);
    }
    fetchData();
  }, []);

  const onChangePage = async (page) => {
    setIsLoading(true);
    const response = await getAlerts({ page });
    setData(response);
    setIsLoading(false);
  }

  return(
    <div
      style={{ width: `calc(100% - ${collapsed ? 80 : 230}px)`, position: 'relative' }}
    >
      <If test={isLoading}>
        <Loading key="loading" />
      </If>
      <If test={!isLoading}>
        <AlertsView
          data={data}
          onChangePage={onChangePage}
        />
        <Footer />
      </If>
    </div>
  )
}