import React, { useContext, useState, useEffect } from 'react';
import { CollapsedContext } from '@/contexts';
import { Loading, If, Footer } from '@/components';
import BoletinsView from './components/BoletinsView';

import { getBulletins } from '@/services/salaDeSituacaoApi';

export function Boletins(props) {
  const { collapsed } = useContext(CollapsedContext);
  const [data, setData] = useState();
  const [type, setType] = useState(props.location.state);
  const [month, setMonth] = useState();
    const [year, setYear] = useState();
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const response = await getBulletins({ type, month, year });
      setData(response);
      setIsLoading(false);
    }
    fetchData();
  }, [type, month, year]);

  const onChangePage = async (page) => {
    setIsLoading(true);
    const response = await getBulletins({ page, type });
    setData(response);
    setIsLoading(false);
  }

  return(
    <div
      style={{ width: `calc(100% - ${collapsed ? 80 : 230}px)`, position: 'relative' }}
    >
      <If test={isLoading}>
        <Loading key="loading" />
      </If>
      <If test={!isLoading}>
        <BoletinsView
          data={data}
          onChangePage={onChangePage}
          type={type}
          setType={setType}
          setMonth={setMonth}
          setYear={setYear}
        />
        <Footer />
      </If>
    </div>
  )
}