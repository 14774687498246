export const TYPES = {
  min: 'tmin',
  max: 'tmax',
  precipitation: 'prec'
}

export const PROVIDERS = {
  somar: 'somar',
  wublast: 'wublast'
}

export const getLayerUrl = (type = 'prec', day = '1') =>
  `https://s3.amazonaws.com/sema-imagery/days/${type}_model_overlay_sema2p5_${day}.png`

export const getAnimatedCurrentLayerUrl = () =>
  `https://moonpng.climatempo.io/instants/observed/abi-l2-cmipf/cmi_ch13/?colorbar=cmi_ch13&details=f&showcolorbar=f&lonmin=-63.13916015625001&lonmax=-43.70288085937501&latmin=-37.542762387234845&latmax=-22.55235365216548&rubish=` + parseInt(Math.random(0, 1) * 10000000);

export const getCurrentLayerUrl = () =>
  `https://moonpng.climatempo.io/instants/observed/abi-l2-cmipf/cmi_ch13/?colorbar=cmi_ch13&details=f&showcolorbar=f&lonmin=-63.13916015625001&lonmax=-43.70288085937501&latmin=-37.542762387234845&latmax=-22.55235365216548&rubish=`  + parseInt(Math.random(0, 1) * 10000000);


