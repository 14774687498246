import React from 'react';
import PropTypes from 'prop-types';
import { Sidebar } from '@/components';
import './../index.styl';

export function Content({ children }) {
  return (
    <div className="content">
      <Sidebar />
      {children}
    </div>
  )
}

Content.propTypes = {
  children: PropTypes.node.isRequired
}
