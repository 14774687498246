import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { If } from '@/components';
import { Row, Col } from  'antd';
import _ from 'lodash';
import './index.styl';

const NewsShowcase = ({ showcaseData }) => {
  const renderShowCase = () => {
    return _.map(showcaseData, news => {
      const style = { 
        backgroundImage: `url(data:image/png;base64,${news.base64})`,
      }
      return (

        <Col className="Col NewsShowcase" xs={24} lg={12} xl={8} span={8} key={news.id} >
          <If test={news.categoria === 'Clipping'}>
            <a href={news.corpo} target='_blank' rel='noopener noreferrer'>
              <div className="NewsShowcase__image" style={style}>
                <div className="NewsShowcase__content">
                  <span>{news.categoria}</span>
                  <p>{news.titulo} - {moment(news.data).format('DD/MM/YYYY')}</p>
                </div>
              </div>
            </a>
          </If>
          <If test={news.categoria === 'Notícia'}>
            <Link to={`noticias/${news.id}`}>
              <div className="NewsShowcase__image" style={style}>
                <div className="NewsShowcase__content">
                  <span>{news.categoria}</span>
                  <p>{news.titulo}</p>
                </div>
              </div>
            </Link>
          </If>
        </Col>
      )
    });
  };

  return (
    <Row gutter={16}>
      {renderShowCase()}
    </Row>
  )
}

export default NewsShowcase;
