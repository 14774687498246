import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { TypeContext } from '@/contexts';
import { TYPES, PROVIDERS } from '@/services/layers';
import './index.styl';

const TabButtons = memo(({ findMapLayer, day }) => {
  const { type, setType } = useContext(TypeContext);

  const setActivable = selfType => (type === selfType ? 'primary' : 'default');

  const setMapLayer = ({ day, type }) => {
    setType(type)
    findMapLayer({ day, type, provider: PROVIDERS.somar })
  }

  return (
    <div className="tab-pane">
      <Button
        type={setActivable(TYPES.min)}
        onClick={() => setMapLayer({ day, type: TYPES.min })}
      >
        Temperatura mínima
      </Button>
      <Button
        type={setActivable(TYPES.max)}
        onClick={() => setMapLayer({ day, type: TYPES.max })}
      >
        Temperatura máxima
      </Button>
      <Button
        type={setActivable(TYPES.precipitation)}
        onClick={() => setMapLayer({ day, type: TYPES.precipitation })}
      >
        Precipitação
      </Button>
    </div>
  )
})

TabButtons.propTypes = {
  findMapLayer: PropTypes.func.isRequired,
  day: PropTypes.number.isRequired
}

export default TabButtons
