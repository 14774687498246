import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { TYPES } from '@/services/layers';

export const TypeContext = createContext({});

export function TypeProvider({ children }) {
  const [type, setType] = useState(TYPES.min);

  const value = {
    type,
    setType
  }

  return <TypeContext.Provider value={value}>{children}</TypeContext.Provider>
}

TypeProvider.propTypes = {
  children: PropTypes.node.isRequired
}
