import React from 'react';
import { Footer } from '@/components';
import About from './components/About';
import Team from './components/Team';
import Sponsors from './components/Sponsors';
import './index.styl';

export const Info = () => {
  return (
    <div>
      <div className="Info__wrapper">
        <About />
        <Team />
        <Sponsors />
      </div>
      <Footer />
    </div>
  )
}