import React, { useState } from 'react';
import { Map } from '@/containers';
import { MapCaption } from '@/components';
import TabsContainer from './components/TabsContainer';
import './index.styl';

const getLayerUrl = (type = 'prec') =>
  `https://s3.amazonaws.com/sema-imagery/penthads/${type}_model_overlay_sema2p5_penthad.png`;

export function Mapeable() {
  const [layerUrl, setLayerUrl] = useState(getLayerUrl())
  const [mapCaption, setMapCaption] = useState('precPentada')

  const setMapLayer = type => {
    setLayerUrl(getLayerUrl(type))
    setMapCaption(type)
  }

  return (
    <div className="mapeable">
      <TabsContainer setMapLayer={setMapLayer} />
      <Map layerUrl={layerUrl} />
      <MapCaption caption={mapCaption} />
    </div>
  )
}
