import React, { useContext, useState, useEffect } from 'react';
import { CollapsedContext } from '@/contexts';
import { getNews } from '@/services/salaDeSituacaoApi';
import NewsSingleView from './components/NewsSingleView';
import { Loading, If, Footer } from '@/components'; 

export function NewsSingle(props) {
  const { collapsed } = useContext(CollapsedContext);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const response = await getNews({ id: props.match.params.id });
      setData(response);
      setIsLoading(false)
    }
    fetchData();
  }, []);

  return(
    <div
    style={{ width: `calc(100% - ${collapsed ? 80 : 230}px)`, position: 'relative' }}
    >
      <If test={isLoading}>
        <Loading key="loading" />
      </If>
      <If test={!isLoading}>
        <NewsSingleView data={data} />
        <Footer />
      </If>
    </div>
  )
}