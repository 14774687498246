import React, { useRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import { AutoComplete, DatePicker, Table, Button } from 'antd';
import uuid from 'uuid/v4';
import { ResponsiveContainer } from 'recharts';
import emptyState from '@/resources/charts-empty.png';
import { isEmptyArray, capitalize } from '@/utils';
import { Loading, If } from '@/components';
import { LoadingContext, AgroContext } from '@/contexts';
import { getStations } from '@/services/salaDeSituacaoApi';
import './index.styl';

const Range = DatePicker.RangePicker

export default function Content({
  tableColumns,
  tableSource,
  getCallable,
  children
}) {
  const table = useRef(null);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { data, setData } = useContext(AgroContext);
  const [stations, setStations] = useState({ isLoading: true });

  /**
   * @description Can not select days before 01-07-2018
   */
  const disabledDate = date =>
    date < moment('2018-07-01T12:00:00Z') || date > moment().subtract(1, 'days');

  const isEmptyState = _.isEmpty(data.period) || _.isEmpty(data.station);
  const isLoaded = !isEmptyArray(tableSource);
  const hasData = !_.isEmpty(data.data);

  useEffect(() => {
    async function fetchData() {
      const res = await getStations(true);
      setStations({ data: res, isLoading: false});
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (hasData) {
      setIsLoading(false)
      getCallable(data.data)
    }
  }, [tableColumns, data.data]);

  let autocompleteSource;

  if (!stations.isLoading) {
    autocompleteSource = stations.data.map(({ name }) => ({
      text: capitalize(name),
      value: name,
      key: uuid()
    }));
  }

  const whenStationSelected = name => {
    const findByName = station => station.name === name;
    setData({
      ...data,
      shouldFetchNewData: true,
      station: stations.data.find(findByName),
      stationName: name
    });
  }

  const whenPeriodChange = event => {
    const [initial, final] = event;
    setData({
      ...data,
      shouldFetchNewData: true,
      period: {
        initial: initial.format('YYYY-MM-DD'),
        final: final.format('YYYY-MM-DD') 
      }
    });
  }

  const dataSource = tableSource.map(source => ({
    ...source,
    station: _.get(data, 'station.name', '')
  }));

  const getCurrentStation = () => _.get(data, 'stationName');

  const getCurrentPeriod = () => {
    const initial = _.get(data, 'period.initial');
    const final = _.get(data, 'period.final');

    return initial && final ? [moment(initial), moment(final)] : null;
  }

  return (
    <div className="content">
      <div className="filters">
        <label>Estação</label>
        <AutoComplete
          className="autocomplete"
          onChange={whenStationSelected}
          dataSource={autocompleteSource}
          filterOption={(inputValue, option) =>
            option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          size="large"
          defaultValue={getCurrentStation()}
        />

        <label>Período</label>
        <Range
          onChange={whenPeriodChange}
          locale={locale}
          disabledDate={disabledDate}
          format="DD/MM/YYYY"
          defaultValue={getCurrentPeriod()}
        />
      </div>

      <div className="container">
        <If test={isEmptyState && !hasData}>
          <div className="empty">
            <h2>
              Para visualizar os dados, selecione uma estação e um período nos
              campos acima.
            </h2>
            <img alt="Empty state" src={emptyState} />
          </div>
        </If>
        <If test={isLoading}>
          <Loading key="loading" />
        </If>
        <If test={isLoaded}>
          <div ref={table}>
            <ResponsiveContainer width="100%" height={300}>
              {children}
            </ResponsiveContainer>
            <p className="disclaimer">A informação apresentada foi gerada a partir da interpolação de dados de estações disponíveis.</p>
            <Table
              className="table"
              align="center"
              columns={tableColumns}
              dataSource={dataSource}
            />
          </div>
        </If>
      </div>
    </div>
  )
}

Content.propTypes = {
  getCallable: PropTypes.func.isRequired,
  tableColumns: PropTypes.array.isRequired,
  tableSource: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired
}
