import React, { lazy, Suspense, memo } from 'react';
import PropTypes from 'prop-types';
import { Icon as Iconable } from 'antd';

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);
const load = name => lazy(() => import(`./Icons/${capitalize(name)}`));

export const Icon = memo(({ name, ...props }) => {
  const Component = load(name);
  return (
    <Suspense fallback={null}>
      <Iconable component={Component} {...props} />
    </Suspense>
  )
})

Icon.propTypes = {
  name: PropTypes.string.isRequired
}

Icon.defaultProps = {
  name: PropTypes.oneOf(['map', 'tractor'])
}
