import React from 'react'
import { Layout, Row, Col, Typography, Pagination } from 'antd'
import _ from 'lodash'
import BoletinsSider from '../Sider'
import { If, PdfPreview } from '@/components'
import './index.styl'

const { Content } = Layout
const { Title } = Typography
const titles = {
  Diario: 'Boletim Hidrometeorológico Diário',
  Semanal: 'Boletim Hidrometeorológico Semanal',
  Mensal: 'Boletim Hidrometeorológico Mensal',
  Especial: 'Boletim Especial'
}

const BoletinsView = ({
  data,
  onChangePage,
  type,
  setType,
  setMonth,
  setYear
}) => {
  const renderBoletins = () => {
    return _.map(data.data, bulletin => {
      return (
        <Col
          xs={24}
          lg={12}
          xl={8}
          className="Boletins__preview"
          key={bulletin.id}
        >
          <PdfPreview route={'boletins'} pdf={bulletin} />
        </Col>
      )
    })
  }

  return (
    <Layout>
      <Content className="Boletins__content">
        <Row className="Row" type="flex" justify="center">
          <Title className="Boletins__title">
            Boletins emitidos pela Sala de Situação
          </Title>
          <Col className="Boletins__subtitle" xs={30} lg={36}>
            <p>
              Atualmente são gerados periodicamente 04 boletins: Boletins
              Meteorológicos Diáriose os Boletins Hidrometeorologicos Semanais,
              Mensais e Especiais.
            </p>
          </Col>
        </Row>
        <Row gutter={8}>{renderBoletins()}</Row>
        <If test={data.pagination}>
          <Pagination
            defaultCurrent={1}
            total={data.pagination.total}
            defaultPageSize={1}
            onChange={onChangePage}
            current={data.pagination.current}
          />
        </If>
      </Content>
      <BoletinsSider
        type={type}
        setType={setType}
        setMonth={setMonth}
        setYear={setYear}
      />
    </Layout>
  )
}

export default BoletinsView
