import React, { useState, useContext, useEffect } from 'react';
import { Layout } from 'antd';
import { MapBoundsContext, CollapsedContext } from '@/contexts';
import { Map } from '@/containers';
import { MapCaption, If, BoletinsSidebar } from '@/components';
import TabsContainer from './components/TabContainer';
import PauseButton from './components/PauseButton';
import StationsButton from './components/StationsButton';
import Spotlight from './components/Spotlight';
import {
  getAnimatedCurrentLayerUrl,
  getCurrentLayerUrl,
  getLayerUrl
} from '@/services/layers';
import { getSpotlight } from '@/services/salaDeSituacaoApi';
import './index.styl';

const { Content } = Layout;

export function Forecast() {
  const { mapBounds, setMapBounds } = useContext(MapBoundsContext);
  const { collapsed } = useContext(CollapsedContext);
  const [bounds, setBounds] = useState(mapBounds);
  const [layerUrl, setLayerUrl] = useState(
    getAnimatedCurrentLayerUrl(mapBounds.flat())
  );
  const [mapCaption, setMapCaption] = useState('satellite');
  const [showMarkers, setShowMarkers] = useState(false);
  const [spotlight, setSpotlight] = useState({});

  const changeCaption = type => {
    type ? setMapCaption(type) : setMapCaption('satellite');
  }

  const whenSomar = ({ type, day }) => {
    setBounds([
      [-22.55235365216548, -43.70288085937501],
      [-37.542762387234845, -63.13916015625001]
    ]);

    setLayerUrl(getLayerUrl(type, day));
    setShowMarkers(false);
  }

  const whenWublast = () => {
    setBounds(mapBounds);
    setLayerUrl(getAnimatedCurrentLayerUrl(bounds.flat()));
  }

  const mapLayerByProvider = ({ provider, type, day }) => {
    const mapping = {
      somar: whenSomar,
      wublast: whenWublast
    }

    mapping[provider]({ type, day });
    changeCaption(type);
  }

  const showPauseButton = () => {
    return !showMarkers && (layerUrl === getAnimatedCurrentLayerUrl(bounds.flat()) ||
      layerUrl === getCurrentLayerUrl(bounds.flat())) ? (
      <PauseButton pauseLayer={pauseLayer} />
    ) : null
  }

  const pauseLayer = () => {
    layerUrl === getAnimatedCurrentLayerUrl(bounds.flat())
      ? setLayerUrl(getCurrentLayerUrl(bounds.flat()))
      : setLayerUrl(getAnimatedCurrentLayerUrl(bounds.flat()))
  }

  const stationsLayer = () => {
    setMapBounds([
      [-20.55235365216548, -43.70288085937501],
      [-37.542762387234845, -63.13916015625001]
    ]);
    setShowMarkers(!showMarkers);
  }

  useEffect(() => {
    async function fetchData() {
      const response = await getSpotlight();
      setSpotlight(response);
    }
    fetchData();
  }, []);

  return (
    <Layout>
      <div className="forecast">
      <Content style={{ width: `calc(100% - ${collapsed ? 160 : 480}px)`, position: "fixed" }}>
        <TabsContainer setMapLayer={mapLayerByProvider} />
          <Map layerUrl={layerUrl} overlayBounds={bounds} showMarkers={showMarkers}/>
          {showPauseButton()}
        <StationsButton onClick={stationsLayer} showMarkers={showMarkers} />
        <MapCaption caption={mapCaption} />
        <If test={spotlight.ativo}>
          <Spotlight data={spotlight} />
        </If>
      </Content>
      </div>
      <BoletinsSidebar />
    </Layout>
  )
}
