import React from 'react';
import { Row, Col, Card } from 'antd';
import _ from 'lodash';
import './index.styl';

const { Meta } = Card;

const NewsOthers = ({ otherData }) => {
  const renderNews = () => {
    return _.map(otherData, news => {
      return (
        <Col className="NewsOthers--Col" xs={24} md={12} lg={8} xl={6}>
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src={`data:image/png;base64,${news.imagem.base64}`} />}
          >
            <Meta
              title={news.categoria}
              description={news.titulo}
            />
          </Card>
        </Col>
      )
    });
  }
  return (
    <Row gutter={16}>
      {renderNews()}
    </Row>
  )
}

export default NewsOthers;
