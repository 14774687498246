import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const CollapsedContext = createContext({});

export function CollapsedProvider({ children }) {
  const [collapsed, setCollapsed] = useState(false);

  const value = {
    collapsed,
    setCollapsed
  };

  return (
    <CollapsedContext.Provider value={value}>
      {children}
    </CollapsedContext.Provider>
  )
}

CollapsedProvider.propTypes = {
  children: PropTypes.node.isRequired
}
